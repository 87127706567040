import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportRoutingModule } from './support-routing.module';
import { SupportComponent } from './support.component';
import { NewIssueModalComponent } from './components/new-issue-modal/new-issue-modal.component';
import { IssueAddModalComponent } from './components/issue-add-modal/issue-add-modal.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { TranslocoModule } from '@ngneat/transloco';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LaddaModule } from 'angular2-ladda';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		CKEditorModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslocoModule,
		SupportRoutingModule,
		LaddaModule
	],
	declarations: [
		SupportComponent,
		NewIssueModalComponent,
		IssueAddModalComponent
	],
	exports: [
		IssueAddModalComponent
	],
	/*entryComponents: [
		IssueAddModalComponent
	]*/
})
export class SupportModule { }
