<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
    <c-container [fluid]="true">
        <button toggle="visible"
                cHeaderToggler
                [cSidebarToggle]="sidebarId"
                class="ps-1">
               <i class="fa fa-bars"></i>
        </button>
        <c-header-nav class="d-none d-lg-flex me-auto">
            <!--
            <c-nav-item>
                <a cNavLink
                   routerLink="/dashboard"
                   routerLinkActive="active">
                    Dashboard
                </a>
            </c-nav-item>
            <c-nav-item>
                <a cNavLink
                   routerLink="/users"
                   routerLinkActive="active">Users</a>
            </c-nav-item>
            <c-nav-item>
                <a cNavLink
                   routerLink="/settings"
                   routerLinkActive="active">
                    Settings
                </a>
            </c-nav-item>
            -->
        </c-header-nav>

        <c-header-nav class="d-none d-lg-flex">
            <!--<c-nav-item>
                <a routerLink="./"
                   cNavLink>
                    <svg cIcon
                         name="cilBell"
                         size="lg"></svg>
                </a>
            </c-nav-item>
            <c-nav-item>
                <a routerLink="./"
                   cNavLink>
                    <svg cIcon
                         name="cilList"
                         size="lg"></svg>
                </a>
            </c-nav-item>
            <c-nav-item>
                <a routerLink="./"
                   cNavLink>
                    <svg cIcon
                         name="cilEnvelopeOpen"
                         size="lg"></svg>
                </a>
            </c-nav-item>-->
        </c-header-nav>
        <c-header-nav class="ms-3">
            <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
        </c-header-nav>

    </c-container>
    <c-header-divider></c-header-divider>
    <c-container [fluid]="true">
        <app-breadcrumb [fixed]="true" class="no-print"></app-breadcrumb>
    </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
    <c-dropdown alignment="end"
                variant="nav-item"
                *transloco="let t">
        <button cButton
                color=""
                [caret]="false"
                cDropdownToggle
                class="py-0">
                <i class="fa fa-user"
					   style="font-size: 20px;"></i>
                {{user?.getFullName()}}
        </button>
        <ul cDropdownMenu
            class="pt-0 pr-0 w-auto">
            <li>
                <h6 cDropdownHeader
                    class="bg-light fw-semibold py-2">{{user?.getFullName()}}</h6>
            </li>
            <li>
                <a  [routerLink]="['/users', user.id, 'details' ]"
                   cDropdownItem>
                   <i class="fa fa-user"></i>
                   {{ t('layout.header.profile') }}
                </a>
            </li>
            <li>
                <a (click)="openSupportModal($event)"
                   cDropdownItem>
                    <i class="fas fa-bug"></i> {{ t('support.issue.add.add_button') }}
                </a>
            </li>
            <li *ngIf="!switchedUser && canSwitchUser()">
                <a (click)="startSwitchUser($event)"
                   cDropdownItem>
                    <i class="fas fa-exchange-alt"></i>  {{ t('layout.header.start_switch_user') }}
                </a>
            </li>
            <li *ngIf="switchedUser">
                <a (click)="stopSwitchUser($event)"
                   cDropdownItem>
                    <i class="fas fa-exchange-alt"></i>  {{ t('layout.header.stop_switch_user') }}
                </a>
            </li>
            <li>
                <a [routerLink]="['/logout']"
                   cDropdownItem>
                   <i class="fa fa-lock"></i> {{ t('layout.header.logout') }}
                </a>
            </li>
        </ul>
    </c-dropdown>
</ng-template>