import { InjectionToken, Injectable } from '@angular/core';
import { HashMap, Translation, TranslocoTranspiler } from '@ngneat/transloco';
import { getValue, isString, isObject, setValue, isDefined } from '@ngneat/transloco';
import { fromEventPattern } from 'rxjs';
import { isNgTemplate } from '@angular/compiler';

@Injectable()
export class DeepTranspiler implements TranslocoTranspiler {

	public searchValue(path: string, params: Object) {
		let keys = path.split('.');
		let value = params;
		for(let key of keys) {
			if (isDefined(value[key])) {
				value = value[key];
			} else  {
				let funcDef = this.getFunctionDef(key);
				if (funcDef) {
					value = value[funcDef.name].call(value);
				} else {
					return undefined;
				}
			}
		}
		return value;
	}

	private getFunctionDef(key) {
		let match = key.match(/^(.*?)\(\)/);

		if (match) {
			return {
				name: match[1],
				arguments: []
			}
		}

		return null;
	}

	transpile(value: any, params: Object = {}, translation: Translation): any {
		if (isString(value)) {
			return value.replace(/{{(.*?)}}/g, (_, match) => {
				match = match.trim();
				let value = this.searchValue(match, params);
				if (value) {
					return value;
				}

				return isDefined(translation[match]) ? translation[match] : '';
			});
		}

		if (isObject(value) && params) {
			/**
			 *
			 * @example
			 *
			 * const en = {
			 *  a: {
			 *    b: {
			 *      c: "Hello {{ value }}"
			 *    }
			 *  }
			 * }
			 *
			 * const params =  {
			 *  "b.c": { value: "Transloco "}
			 * }
			 *
			 * service.selectTranslate('a', params);
			 *
			 * // the first param will be the result of `en.a`.
			 * // the second param will be `params`.
			 * parser.transpile(value, params, {});
			 *
			 *
			 */
			Object.keys(params).forEach(p => {
				// get the value of "b.c" inside "a" => "Hello {{ value }}"
				const v = getValue(value, p);
				// get the params of "b.c" => { value: "Transloco" }
				const getParams = getValue(params, p);

				// transpile the value => "Hello Transloco"
				const transpiled = this.transpile(v, getParams, translation);

				// set "b.c" to `transpiled`
				value = setValue(value, p, transpiled);
			});
		}

		return value;
	}
}