import { User } from './models/user';

/** @ignore */
interface NavAttributes {
	[propName: string]: any;
}
/** @ignore */
interface NavWrapper {
	attributes: NavAttributes;
	element: string;
}
/** @ignore */
interface NavBadge {
	text: string;
	variant: string;
}
/** @ignore */
interface NavLabel {
	class?: string;
	variant: string;
}

/** @ignore */
export interface NavData {
	name?: string;
	url?: string;
	icon?: string;
	badge?: NavBadge;
	title?: boolean;
	children?: NavData[];
	variant?: string;
	attributes?: NavAttributes;
	divider?: boolean;
	class?: string;
	label?: NavLabel;
	wrapper?: NavWrapper;
	translated?: boolean;
	permissions?: string[];
	show?: (user: User) => boolean;
}

/**{
		name: 'layout.menu.news_article_menu_item',
		url: '/news',
		icon: 'far fa-newspaper' // icon-speedomerter
	}, */

/** @ignore */
export const navItems: NavData[] = [
	{
		name: 'layout.menu.dashboard_menu_item',
		url: '/dashboard',
		icon: 'fa fa-tachometer-alt' // icon-speedomerter
	},
	{
		name: 'layout.menu.resources_title',
		title: true,
		permissions: ['permission.site.list', 'permission.customer.list']
	},
	{
		name: 'layout.menu.sites_menu_item',
		url: '/sites',
		icon: 'fas fa-plane',
		permissions: ['permission.site.list']
	},
	{
		name: 'layout.menu.customers_menu_item',
		url: '/customers',
		icon: 'fa fa-user-tie',
		permissions: ['permission.customer.list']
	},
	{
		name: 'layout.menu.project_management_title',
		title: true,
		permissions: [
			'permission.quotation.list',
			'permission.quotation.own.list',
			'permission.project.list',
			'permission.worksheet.list',
			'permission.worksheet.own.list',
			'permission.timsheet.list',
			'permission.timesheet.own.list',
			'permission.employee-timesheet.list',
			'permission.employee-timesheet.own.list'
		]
	},
	{
		name: 'layout.menu.quotations_menu_item',
		url: '/quotations',
		icon: 'fa fa-comment-dollar',
		permissions: ['permission.quotation.list', 'permission.quotation.own.list']
	},
	{
		name: 'layout.menu.projects_menu_item',
		url: '/projects',
		icon: 'fa fa-puzzle-piece',
		permissions: ['permission.project.list']
	},
	{
		name: 'layout.menu.worksheets_menu_item',
		url: '/worksheets',
		icon: 'fas fa-file-signature',
		permissions: ['permission.worksheet.list', 'permission.worksheet.own.list']
	},
	{
		name: 'layout.menu.invoicing_title',
		title: true,
		permissions: [
			'permission.sales-invoicing.list'
		]
	},
	{
		name: 'layout.menu.sale_invoices_menu_item',
		url: '/invoicing',
		icon: 'fa fa-file-invoice-dollar',
		permissions: ['permission.sales-invoicing.list'],
		class: 'disabled'
	},
	{
		name: 'layout.menu.personal_title',
		title: true,
		permissions: [
			'permission.leave.list',
			'permission.leave.own.list',
			'permission.timesheet.list',
			'permission.timesheet.own.list',
			'permission.employee-timesheet.list',
			'permission.employee-timesheet.own.list',
		],
	},
	{
		name: 'layout.menu.worker_timesheets_menu_item',
		url: '/timesheets/worker',
		icon: 'fas fa-stopwatch',
		permissions: ['permission.timesheet.list', 'permission.timesheet.own.list']
	},
	{
		name: 'layout.menu.employee_timesheets_menu_item',
		url: '/timesheets/employee',
		icon: 'fas fa-stopwatch',
		permissions: ['permission.employee-timesheet.list', 'permission.employee-timesheet.own.list'],
		/*show: (user: User): boolean => {
			if (user.hasPermission('permission.timesheet.own.list') && user.employee) {
				return true;
			}

			return user.hasPermission('permission.timesheet.list');
		}*/
	},
	{
		name: 'layout.menu.leaves_menu_item',
		url: '/leaves',
		icon: 'fas fa-toggle-off',
		permissions: ['permission.leave.list', 'permission.leave.own.list']
	},
	{
		name: 'layout.menu.financial_title',
		title: true,
		permissions: [
			'permission.report.list'
		]
	},
	{
		name: 'layout.menu.reports_menu_item',
		url: '/reports',
		icon: 'fa fa-chart-line',
		permissions: ['permission.report.list']
	},
	{
		name: 'layout.menu.administration_title',
		title: true,
		permissions: [
			'permission.user.admin',
			'permission.public-holiday.admin',
			'permission.employee.admin',
			'permission.worker.admin',
			'permission.subcontractor.admin'
		]
	},
	{
		name: 'layout.menu.administration_menu_item',
		url: '/administration',
		icon: 'fa fa-cog',
		permissions: [
			'permission.user.admin',
			'permission.public-holiday.admin',
			'permission.employee.admin',
			'permission.worker.admin',
			'permission.subcontractor.admin'
		]
	}
];
