import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/api/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatchValues } from '../../validators/match-values.validator';
import { errorAnimation } from '../../animations/error.animation';

/**
 * Represents a component to indicate the reset code and a new password
 * and send it to the server
 */
@Component({
	selector: 'app-password-reset-validate',
	templateUrl: './password-reset-validate.component.html',
	styleUrls: ['./password-reset-validate.component.scss'],
	animations: [
		errorAnimation
	]
})
export class PasswordResetValidateComponent implements OnInit {
	/** The main form */
	public form: UntypedFormGroup;
	/** Indicates if the request is running or not */
	public isLoading: boolean = false;
	/** The error code if any */
	public error: number = null;
	/** Indicates if the form has been submitted or not */
	public submit: boolean = false;
	/** The email of the account to reset */
	public email: string;
	/** Indicates if the requests succeed or not */
	public success: boolean = false;
	/** The duration of the display of the success message */
	public delay: number = 10;

	/**
	 * Constructs the component
	 * @param formBuilder
	 * @param router
	 * @param activatedRoute
	 * @param auth
	 */
	constructor(
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private auth: AuthService) {
		this.form = this.formBuilder.group({
			code: ['', Validators.required],
			password: ['', Validators.required],
			passwordConfirm: ['', Validators.required]
		}, {
			validator: MatchValues('password', 'passwordConfirm')
		});
	}

	/**
	 * Callback called when the component is initialized
	 */
	ngOnInit() {
		this.email = this.activatedRoute.snapshot.paramMap.get('email');
	}

	/**
	 * Sends the email, the reset code and the passord to the server.
	 * If the request succeed, displays a success message and then
	 * redirect to the login page
	 * If the request failed, displays the error
	 */
	savePassword() {
		if (!this.form.valid) {
			return false;
		}

		this.isLoading = false;
		this.error = null;

		this.auth.validatePassword(
			this.email,
			this.form.get('code').value,
			this.form.get('password').value
		).subscribe(result => {
			this.isLoading = false;
			this.success = true;
			setTimeout(() => {
				this.router.navigateByUrl('/login');
			}, this.delay * 1000);
		}, err => {
			this.isLoading = false;
			this.error = err.status;
		});
	}

}
