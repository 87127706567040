//Angular
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
// Libs
import { Observable, throwError, of } from 'rxjs';
// App
import { SessionService } from './session.service';
import { AuthService } from './api/auth.service';
import { Location } from '@angular/common';

/**
 * Guard that canActivate if the user is authenticated.
 *
 * @author Vincent Dieltiens <v.dieltiens@jdc-airports.com>
 */
@Injectable({
	providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

	/**
	 * Constructs the Guard
	 * @param auth
	 * @param router
	 */
	constructor(
		private auth: AuthService,
		private router: Router,
		private session: SessionService,
		private location: Location
	) {}

	/**
	 * Test if the user is authenticated
	 * @returns An observable resolving into true if the user is authenticated, false otherwise
	 */
	canActivate(): Observable<boolean> {
		return new Observable(observer => {
			this.auth.getAuthenticatedUser().subscribe(user => {
				observer.next(true);
				observer.complete();
			}, err => {
				this.session.set('login-redirect-url', this.location.path());
				this.router.navigate(['login']);
				observer.next(false);
				observer.complete();
			});
		});
	}
}
