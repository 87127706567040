import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SessionService {

	data: { [key: string]: any };

	constructor() {
		this.data = {};
	}

	set(key: string, value: any) {
		this.data[key] = value;
	}

	has(key: string) {
		return key in this.data;
	}

	get(key: string, defaultValue: any = undefined) {
		if (!this.has(key) && defaultValue === undefined) {
			throw new Error(`Key ${key} does not exist in session`);
		}
		return this.has(key) ? this.data[key] : defaultValue;
	}

	remove(key: string) {
		if (!this.has(key)) {
			throw new Error(`Key ${key} does not exist in session`);
		}
		delete this.data[key];
	}
}