import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@app/models/user';
import { ApiService } from '@app/services/api/api.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
	selector: 'app-user-switch-modal',
	templateUrl: './user-switch-modal.component.html',
	styleUrls: ['./user-switch-modal.component.scss']
})
export class UserSwitchModalComponent implements OnInit {

	data: any;
	private users: User[];
	private form: UntypedFormGroup;

	public loading: boolean = false;
	protected _onUserSwitched = new Subject();
	public onUserSwitched = this._onUserSwitched.pipe(take(1)).toPromise();

	constructor(
		private modal: BsModalRef,
		private apiService: ApiService,
		private formBuilder: UntypedFormBuilder
	) {
		this.form = formBuilder.group({
			user: ['', Validators.required]
		});
	}

	submit() {
		let user = this.form.get('user').value;
		this._onUserSwitched.next(user);
	}

	async ngOnInit() {
		this.users = await this.apiService.list(User, `/users`);
	}

}
