import { HttpClient } from '@angular/common/http';
import { Translation, TRANSLOCO_LOADER, TranslocoLoader } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

/**
 * Represents the loader of the translations
 */
@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
	/**
	 * Constructs the loader
	 * @param http
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Gets the translations for a given language
	 * @param langPath
	 * @returns the translation
	 */
	getTranslation(langPath: string) {
		return this.http.get<Translation>(`/assets/i18n/${langPath}.json`)
		.pipe(catchError((e, b) => {
			console.log('ERROR : ', e);
			return throwError(e);
		}));
	}
}

/**
 * The Transloco provider
 */
export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };

