import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/api/auth.service';
import { trigger, transition, style, animate, state, useAnimation, sequence, query, stagger } from '@angular/animations';
import { Router } from '@angular/router';
import { fadeOutFadeInAnimation, fadeInAnimation, fadeOutAnimation } from '../../animations/fade-out-fade-in-child.animation';
import { errorAnimation } from '../../animations/error.animation';
import { ngIfAnimation } from '../../animations/ng-if-animation';

/**
 * Represents a component to ask a reset code by mail
 * @author Vincent Dieltiens <v.dieltiens@jdc-airports.com>
 */
@Component({
	selector: 'app-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.scss'],
	animations: [
		errorAnimation
	]
})
export class PasswordResetComponent {
	/** The main form */
	public form: UntypedFormGroup;
	/** Indicates if the request is running */
	public isLoading: boolean;
	/** Indicates if there is an error */
	public error: number;

	/**
	 * Constructs the component
	 * @param formBuilder
	 * @param auth
	 * @param router
	 */
	constructor(private formBuilder: UntypedFormBuilder, private auth: AuthService, private router: Router) {
		this.isLoading = false;
		this.error = 0;
		this.form = this.formBuilder.group({
			email: ['', Validators.required]
		});
	}

	/**
	 * Sends the email to the server to get a password reset code
	 */
	sendResetEmail() {
		this.isLoading = true;
		this.error = 0;
		this.auth.sendResetPassword(this.form.get('email').value).subscribe(res => {
			this.isLoading = false;
			this.router.navigateByUrl('/password-reset-validate/' + this.form.get('email').value);
		}, err => {
			this.isLoading = false;
			this.error = err.status;
		});
	}

}
