import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { map } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { isNgTemplate } from '@angular/compiler';
import { ObjectHydratorService } from '@app/modules/shared/services/object-hydrator.service';
import { LogEntry } from '../user.service';
import { Pagination } from '@app/models/paginator';
import { from, Observable } from 'rxjs';

export interface Model {
	id?: number;
}

type MethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';


function filtersToStr(filters: any, filtersAsSeparatedParams = true, url?: string) {
	//console.log('filters : ', filters)
	let str = [];

	if (filters.page || filters.page === 0) {
		str.push('page='+filters.page);
	}
	if (filters.pageSize) {
		str.push('pageSize='+filters.pageSize);
	}
	if (filters.perPage) {
		str.push('perPage='+filters.perPage);
	}
	if (filters.sortColumn) {
		let direction = filters.sortDirection || 'asc';
		str.push('sort='+filters.sortColumn+':'+direction);
	}

	if (filters.filter) {
		let filter;
		if (typeof filters.filter == 'string') {
			filter = JSON.parse(filters.filter);
		} else {
			filter = filters.filter;
		}

		if (filtersAsSeparatedParams) {
			Array.from(Object.entries(filter))
				.map(item => str.push(item[0]+'='+item[1]));
		} else {
			let filterStr = Array.from(Object.entries(filter))
				.map(item => item[0]+':'+item[1])
				.join(',');
			str.push('filter='+filterStr);
		}
	}

	let hasQueryParam = url && url.indexOf('?') > 0;

	return str.length ? (hasQueryParam ? '&' : '?') + str.join('&') : '';
}

/**
 * Simple interface to communicate with the backend
 *
 * @author Vincent Dieltiens <v.dieltiens@jdc-airports.com>
 */
@Injectable({
	providedIn: 'root'
})
export class ApiService {

	private backendBaseUrl;

	constructor(
		private http: HttpClient,
		private appConfig: AppConfigService,
		private hydrator: ObjectHydratorService) {
		this.backendBaseUrl = appConfig.get('backendBaseUrl');
	}

	/**
	 * Gets a list of an entity type
	 * @param c the type of the entity
	 * @param url the url to fetch the entities
	 * @returns a promise with a list of object
	 */
	list<T>(c: new () => T, url: string, filters = {}, hydrate: boolean = true): Promise<T[]> {
		let list = this.http.get<T[]>(`${this.backendBaseUrl}${url}${filtersToStr(filters, true, url)}`);

		if (hydrate) {
			list = list.pipe(map(data => {
				const items = [];
				for (const dataItem of data) {
					const item = this.hydrator.hydrate(c, dataItem);
					//const item = new c();
					//Object.assign(item, dataItem);
					items.push(item);
				}
				return items;
			}));
		}


		return list.toPromise();
	}

	/**
	 * Gets a paginated list of an entity type
	 * @param c the type of the entity
	 * @param url the url to fetch the entities
	 * @returns a promise with a list of object
	 */
	listPaginate<T>(c: new () => T, url: string, filters = {}, hydrate: boolean = true): Promise<Pagination<T>> {
		let pagination = this.http.get<Pagination<T>>(`${this.backendBaseUrl}${url}${filtersToStr(filters, true, url)}`);

		if (hydrate) {
			pagination = pagination.pipe(map(data => {
				data.results = data.results.map((item: T) => {
					return this.hydrator.hydrate(c, item);
				});
				return data;
			}));
		}

		return pagination.toPromise();
	}

	/**
	 * Gets an entity from the backend
	 * @param c th eclass of the entity to get
	 * @param url the url of the backend
	 * @param the id of the entity to fetch
	 * @returns A promise of the entity
	 */
	get<T>(c: new () => T, url: string, id: number | string, headers: any = {}, filters = {}): Promise<T> {
		let options: any = {};
		for(let key of Object.keys(headers)) {
			if (!('headers' in options)) {
				options.headers = {};
			}
			options.headers[key] = headers[key];
		}
		return this.http.get<T>(`${this.backendBaseUrl}${url}${filtersToStr(filters, true, url)}`, options)
			// Convert result to the entity
			.pipe(map((data) => {
				const item = this.hydrator.hydrate(c, data);
				return item;
				/*const item = new c();
				Object.assign(item, data);
				return item;*/
			}))
			.toPromise();
	}

	/**
	 * Deletes an entity from the backend
	 * @param c the class of the entity
	 * @param url the url of the backend
	 * @param object the object to delete
	 * @returns a promise of the entity
	 */
	delete<T>(c: new () => T, url: string, object: T) {
		if (!(object as any).id) {
			throw new Error('Cant\'t delete an entity without id');
		}
		return this.http.delete<T>(`${this.backendBaseUrl}${url}`)
			.pipe(map(data => {
				const item = new c();
				Object.assign(item, data);
				return item;
			}))
			.toPromise();
	}

	/**
	 * Creates a new entity on the backend.
	 * @param c the class (type) for the entity
	 * @param url the url
	 * @param object the data of the entity
	 * @param checkId true if the method should check for an id, false otherwise
	 * @returns a Promise with the new user (with an id)
	 * @throws {Error} if the user already has an id.
	 */
	create<T extends Model>(c: new () => T, url: string, object: T, checkId: boolean = true): Promise<T> {
		if (object.id && checkId) {
			throw new Error('Can\'t create an entity with an id');
		}
		return this.http.post<T>(`${this.backendBaseUrl}${url}`, object)
			.pipe(map((data) => {
				const item = new c();
				Object.assign(item, data);
				return item;
			}))
			.toPromise();
	}

	/**
	 * Updates an entity on the backend.
	 * @param c the class (type) for the entity
	 * @param url the url
	 * @param object the data of the entity
	 * @param checkId true if the method should check for an id, false otherwise
	 * @returns a Promise with the updated entity (with an id)
	 * @throws {Error} if the entity already has an id.
	 */
	update<T extends Model>(c: new () => T, url: string, object: T, checkId: boolean = true): Promise<T> {
		if (!object.id && checkId) {
			throw new Error('Can\'t update an entity without an id');
		}
		return this.http.put<T>(`${this.backendBaseUrl}${url}`, object)
			.pipe(map((data) => {
				const item = new c();
				Object.assign(item, data);
				return item;
			}))
			.toPromise();
	}

	/**
	 * Get the history of an entity
	 * @param url the url of the backend
	 * @returns a promise of the log entries
	 */
	history<T extends LogEntry>(c: new () => T, url: string): Promise<T[]> {
		return this.http.get<T[]>(`${this.backendBaseUrl}${url}`)
			.pipe(map((data) => {
				const entries = [];
				for (const entryData of data) {
					const entry = this.hydrator.hydrate(c, entryData);
					entries.push(entry);
				}
				return entries;
			}))
			.toPromise();
	}

	/**
	 * Call a custom api
	 * @param c the class name of the entity
	 * @param method the method. Possible values : 'PUT', 'POST', 'PATCH', 'GET' and 'DELETE
	 * @param url the url of the api
	 * @param object an object to send to the api (only with 'PUT', 'POST' and 'PATCH' methods)
	 * @returns a promise with an object or a list of objects
	 */
	custom<T>(c: (new () => T | null), method: MethodType, url: string, object?: Object): Promise<T | T[]> {
		let options: any = {};
		if ((method === 'PUT' || method === 'POST' || method === 'PATCH')
			&& !object
		) {
			throw new Error("should set an object with put, post or path methods");
		}

		if (object) {
			options.body = object;
		}

		return this.http.request<T | T[]>(method, `${this.backendBaseUrl}${url}`, options)
			.pipe(map((res: any) => {
				const data = res;
				if (Array.isArray(data)) {
					const items = [];
					for (const dataItem of data) {
						const item = this.hydrator.hydrate(c, dataItem);
						items.push(item);
					}
					return items;
				} else {
					return this.hydrator.hydrate(c, data);
				}
			}))
			.toPromise();
	}
}
