import { Role, walkRoles, Permission } from '../modules/user/models/role';
import { Relation } from '@app/modules/shared/services/object-hydrator.service';
import { UserPreferences } from './user-preference';
import { UserRole } from '@app/modules/user/models/user_role';

/**
 * Represents a user of the application
 *
 * @author Vincent Dieltiens <v.dieltiens@jdc-airports.com>
 */
export class User {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	mobile_phone_number: string;
	landline_phone_number: string;
	is_active: boolean;
	deactivated_at: Date;
	@Relation(UserRole) user_roles: Array<UserRole> = [];
	@Relation(Permission) permissions: Array<Permission> = [];

	@Relation(UserPreferences) preferences: UserPreferences;

	report_permissions: any[] = [];

	_permissions: any;

	worker: any;

	/**
	 * Get the full name of the user
	 * @returns the full name
	 */
	public getFullName(): string {
		return ((this.first_name || '') + ' ' + (this.last_name || '')).trim();
	}

	public getFullNameInitials(): string {
		return (this.first_name.charAt(0)+this.last_name.substr(0, 2)).toUpperCase();
	}

	public getRolesIds(): number[] {
		if (this.user_roles) {
			return this.user_roles.map(r => r.role.id);
		}
		return [];
	}

	public getRoles(): Role[] {
		if (this.user_roles) {
			return this.user_roles.map(r => r.role);
		}
		return [];
	}

	public setRoles(roles: Role[]): void {
		this.user_roles = roles.map(role => {
			return new UserRole(role);
		});
	}

	public hasRole(roleName: string) {
		return this.user_roles.some(r => r.role.name == roleName);
	}

	/**
	 * Checks if the user has a given permission
	 * @param permission
	 */
	public hasPermission(permission: string) {
		return this.hasPermissions([permission]);
	}

	public hasPermissions(searchedPermissions: string[], conditionalOperator: 'OR' | 'AND' = 'OR'): boolean {
		searchedPermissions = searchedPermissions.map(perm => {
			if (perm.indexOf('permission.') == -1) {
				return 'permission.' + perm;
			}
			return perm;
		});

		//console.log('hasPermissios : ', permissions.join(','))
		function _hasPermissions(roles): boolean {
			//console.log('_hasPermissions : ', roles.map(r => r.name).join(','))
			for(let role of roles) {
				//console.log('check role ', role.name);
				if (role.permissions) {
					for(let permission of role.permissions) {
						//console.log('check for ', permission.name);
						let permIndex = searchedPermissions.indexOf(permission.name);
						if (permIndex >= 0) {
							// has permission
							//console.log('got it')
							if (conditionalOperator == 'OR') {
								return true;
							} else {
								let removedPermissions = searchedPermissions.splice(permIndex, 1);
								if (removedPermissions.length !== 1) {
									throw new Error("User::hasPermissions - permission was not removed");
								}

								if (searchedPermissions.length == 0) {
									return true;
								}
							}
						}
					}
				}

				if (role.children_roles) {
					//console.log('check children of ', role.name);
					if ( _hasPermissions(role.children_roles)) {
						return true;
					}
				}
			}

			if (conditionalOperator == 'AND' && searchedPermissions.length == 0) {
				return true;
			}

			return false;
		}

		if (this.permissions) {
			for(let permission of this.permissions) {
				if (searchedPermissions.indexOf(permission.name) >= 0) {
					return true;
				}
			}
		}

		if (this.user_roles) {
			return _hasPermissions(this.user_roles.map(r => r.role));
		}

		return false;
	}

	public getAllPermissions(): string[] {
		let permissions = [];
		if (this.permissions) {
			for(let permission of this.permissions) {
				permissions.push(permission.name);
			}
		}
		walkRoles(this.getRoles(), role => {
			permissions = permissions.concat(
				(role.permissions || []).map(permission => permission.name)
			);
		});
		return permissions;
	}
}

