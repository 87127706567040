import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '@app/services/api/api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Issue } from '../../models/issue';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import ClassicEditor from '../../../../lib/ckeditor/ckeditor';
import { AuthService } from '@app/services/api/auth.service';
import { StorageService } from '@app/services/storage.service';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { FormComponent } from '@app/modules/shared';

@Component({
	selector: 'app-issue-add-modal',
	templateUrl: './issue-add-modal.component.html',
	styleUrls: ['./issue-add-modal.component.scss']
})
export class IssueAddModalComponent extends FormComponent implements OnInit, OnDestroy {
	public Editor = ClassicEditor;
	ckConfig = null;

	data: any;

	isSubmitted: boolean = false;
	private error?: string = null;
	public loading: boolean = false;
	protected _onContinue = new Subject();
	public onContinue = this._onContinue.pipe(take(1)).toPromise();

	public form: UntypedFormGroup;

	constructor(
		private modal: BsModalRef,
		private modalService: BsModalService,
		private formBuilder: UntypedFormBuilder,
		private apiService: ApiService,
		private authService: AuthService,
		private localStorage: StorageService,
		private transloco: TranslocoService,
		private toastr: ToastrService
	) {
		super();
		this.initCKEditor();
		this.form = formBuilder.group({
			'title': [null, Validators.required],
			'body': [null, Validators.required]
		});
	}

	async initCKEditor() {
		const token = await localStorage.getItem('token');
		this.ckConfig = {
			toolbar: ['imageUpload', 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',  'indent', 'outdent', '|',  'blockQuote', 'insertTable', 'undo', 'redo'],
			height: 200,
			simpleUpload: {
				// The URL that the images are uploaded to.
				uploadUrl: '/api/debug/upload',

				// Enable the XMLHttpRequest.withCredentials property.
				withCredentials: true,

				// Headers sent along with the XMLHttpRequest to the upload server.
				headers: {
					Authorization: 'Bearer '+token
				}
			}
		};
	}

	ngOnInit(): void {

	}

	save() {
		if (!this.form.valid) {
			return;
		}
		let values = this.fromForm(this.form.value);
		this.loading = true;

		this.apiService.create(Issue, '/debug/issues', values).then(() => {

			this.toastr.success(
				this.transloco.translate('support.issue.add.success_message'),
				this.transloco.translate('support.issue.add.success_title'),
			);

			this.modalService.setDismissReason("continue");
			this.modal.hide();
		}, (res) => {
			this.loading = false;
			if (res.status == 400) {
				this.setServerErrors(res.error.errors.children);
			} else {
				this.setError(res.error.message);
			}
		});
	}

	cancel() {
		this.modalService.setDismissReason("cancel");
		this.modal.hide();
	}

	setError(error?: string) {
		this.loading = false;
		this.error = error;
	}

	fromForm(values) {
		const flat: any = Object.assign({}, values);
		return flat;
	}

	ngOnDestroy() {
		this._onContinue.complete();
		this._onContinue.unsubscribe();
	}

}
