import { style, query, animate, group } from '@angular/animations';

/**
 * Fade In (:enter) animation definiton
 */
export const fadeIn = [
	style({ opacity: 100 }),
	query(':enter', [
		style({ opacity: 0 })
	], { optional: true }),
];

/**
 * Fade out (:leave) animation definiton
 */
export const fadeOut = [
	style({ opacity: 100 }),
	query(':leave', [
		style({ opacity: 100 })
	], { optional: true }),
];
