import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/api/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { state, style, animate, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { errorAnimation } from '../../animations/error.animation';
import { SessionService } from '@app/services/session.service';
import { StorageService } from '@app/services/storage.service';

/**
 * Represents a component to authenticate the user in the app
 */
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	animations: [
		errorAnimation
	]
})
export class LoginComponent {

	/** Indicates if there is an error or not */
	public error: any;
	/** The main form */
	public form: UntypedFormGroup;
	/** Indicates if the request is running or not */
	public isLoading: boolean;

	/**
	 * Constructs the component
	 * @param auth
	 * @param formBuilder
	 * @param router
	 */
	constructor(
		private auth: AuthService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private session: SessionService,
		private storageService: StorageService
	) {
		this.error = false;
		this.isLoading = false;
		this.form = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});
		//this.auth.logout().subscribe();
	}

	/**
	 * Log the user in.
	 * It send the username/password to authenticate the user.
	 * If the authentication succeed; the user is redirected to dashboard
	 * If the authentication fails, it displays the error
	 */
	login() {
		this.error = false;
		this.isLoading = true;
		let username = this.form.get('username').value;
		if (username.indexOf('@') < 0) {
			username += '@jdc-airports.com';
		}

		//
		this.storageService.remove('switch_user');

		this.auth.login(username, this.form.get('password').value).subscribe(result => {
			this.isLoading = false;
			let redirectUrl = this.session.get('login-redirect-url', '/');
			redirectUrl = (redirectUrl == '/login') ? '/' : redirectUrl;
			this.router.navigateByUrl(redirectUrl).catch(e => {
				this.router.navigateByUrl('/');
			});
		}, (err) => {
			this.isLoading = false;
			console.log('err : ', err)
			this.error = err;
		});
	}

}
