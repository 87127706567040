import { Relation } from '@app/modules/shared/services/object-hydrator.service';

export class Permission {
    id: number;
    name: string;
    label: string;

    getSection() {
        let search = this.name.match(/^permission\.(.*?)\./);
        return search[1];
    }
}

export class Role {
    id: number;
    name: string;
    @Relation(Role) children_roles?: Role[] = [];
    @Relation(Permission) permissions: Permission[] = [];

    constructor(
        id: number = null,
        name: string = null,
        permissions: Permission[] = [],
        children_roles?: Role[]
    ) {
        this.id = id;
        this.name = name;
        this.permissions = permissions;
        if (children_roles) {
            this.children_roles = children_roles;
        }
    }
}

export function walkRoles(roles, fn): void {
	for(const role of roles) {
		fn(role);
		if (role.children_roles) {
			walkRoles(role.children_roles, fn);
		}
	}
}