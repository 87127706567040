import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { trigger, style, query, group, animate, animation, animateChild, transition, useAnimation } from '@angular/animations';
import { fadeOutFadeInChildAnimation } from './animations/fade-out-fade-in-child.animation';
import { filter, map } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

// [@routeAnimations]="prepareRoute(outlet)"
/**
 * Represents that App component which is the root component of the App
 * @author Vincent Dieltines <v.dieltiens@jdc-airports.com>
 */
@Component({
	// tslint:disable-next-line
	selector: 'body',
	template: `<ngx-loading-bar></ngx-loading-bar>
	<div class="aaa">
		<router-outlet #outlet="outlet"></router-outlet>
	</div>`,
	styleUrls: ['./app.component.scss'],
	animations: [
		trigger('routeAnimations', [
			transition('* => *', [
				useAnimation(fadeOutFadeInChildAnimation, {
					params: {
						time: '0.5s'
					}
				})
			])
		])
	]
})
export class AppComponent implements OnInit {
	/**
	 * Constructs the component
	 * @param router
	 */
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private localService: BsLocaleService
	) {
		this.localService.use('fr');
	}

	/**
	 * Component initialisation
	 */
	ngOnInit() {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			//console.log('===>', evt, this.activatedRoute);
			//window.scrollTo(0, 0);
		});

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.pipe(map(() => {
				let child = this.activatedRoute.firstChild;
				while (child) {
					if (child.firstChild) {
						child = child.firstChild;
					} else {
						return child;
					}
				}
				return child;
			})).subscribe((route: any) => {
				//console.log(route.snapshot.data)
				if (!route.snapshot.data || route.snapshot.data.scrollTop !== false) {
					//console.log('==>', route.snapshot.data.scrollTop)
					window.scrollTo(0, 0);
				}
			});
	}

	/**
	 * Preparation for the animation
	 * @param outlet
	 * @returns the "state" name for a route or false
	 */
	prepareRoute(outlet: RouterOutlet): string | null | false {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
	}
}
