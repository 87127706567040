import { Component } from '@angular/core';
import { IssueAddModalComponent } from '@app/modules/support/components/issue-add-modal/issue-add-modal.component';
import { FooterComponent } from '@coreui/angular';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-default-footer',
    templateUrl: './default-footer.component.html',
    styleUrls: ['./default-footer.component.scss'],
})
export class DefaultFooterComponent extends FooterComponent {
    constructor(
        private modalService: BsModalService
    ) {
        super();
    }

    openSupportModal($event) {

        let initialState = {};

        let modal = this.modalService.show(IssueAddModalComponent, { initialState, class: 'issue-modal' });
    }
}