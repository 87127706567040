import { trigger, transition, style, animate } from '@angular/animations';

/**
 * Angular Animation trigger `errorAnimation`.
 */
export const errorAnimation = trigger('errorAnimation', [
	transition(':enter', [
		style({ opacity: 0 }),
		animate('.5s', style({
			opacity: 1
		}))
	]),
]);
