import { Relation } from '@app/modules/shared/services/object-hydrator.service';
import { Role } from './role';

export class UserRole {
	id?: number;
	@Relation(Role) role: Role;

	constructor(role: Role) {
		this.role = role;
	}
}