<!--sidebar-->
<c-sidebar #sidebar="cSidebar"
           class="d-print-none sidebar sidebar-fixed"
           id="sidebar"
           visible>
  <c-sidebar-brand [brandFull]="{
      src: 'assets/img/brand/logo-cockpit-horizontal.png',
      width: 106,
      height: 45,
      alt: 'Cockpit Logo'
    }"
                   [brandNarrow]="{
      src: 'assets/img/brand/logo-small.png',
      width: 35,
      height: 35,
      alt: 'Cockpit Logo'
    }"
                   routerLink="./">
  </c-sidebar-brand>
  <perfect-scrollbar [config]="perfectScrollbarConfig">
    <!--  [perfectScrollbar]="perfectScrollbarConfig"-->
    <c-sidebar-nav [navItems]="navItems"
                   dropdownMode="close">
    </c-sidebar-nav>
  </perfect-scrollbar>
  <c-sidebar-toggler *ngIf="!sidebar.narrow"
                     toggle="unfoldable"
                     cSidebarToggle="sidebar"></c-sidebar-toggler>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky"
                      position="sticky"
                      sidebarId="sidebar"></app-default-header>
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg"
                 class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer></app-default-footer>
</div>