<form [formGroup]="form"
	  [(jdcFormSubmit)]="isSubmitted"
	  (ngSubmit)="save()"
	  novalidate
	  *transloco="let t;">
	<div class="">
		<h4 class="modal-title">
			<i class="fa fa-bug"></i>
			<span>{{ t('support.issue.add.title') }}</span>
		</h4>
		<div class="modal-body">
			<!-- title -->
			<div class="form-group">
				<label for="title"
					   class="required">{{ t('support.issue.fields.title') }}</label>
				<input type="text"
					   class="form-control"
					   formControlName="title" />
				<div class="form-error"
					 id="title_required_error"
					 *ngIf="form.get('title').errors?.required">
					{{ t('support.issue.form.title.required_error') }}
				</div>
				<div class="form-error"
					 id="title_server_error"
					 *ngIf="form.get('title').errors?.serverError">
					{{ t(form.get('title').errors?.serverError) }}
				</div>
			</div>

			<!-- body -->
			<div class="form-group">
				<label for="body"
					   class="required">{{ t('support.issue.fields.body') }}</label>
				<ckeditor [editor]="Editor"
						*ngIf="ckConfig"
						  [config]="ckConfig"
						  formControlName="body"></ckeditor>
				<div class="form-error"
					 id="body_required_error"
					 *ngIf="form.get('body').errors?.required">
					{{ t('support.issue.form.body.required_error') }}
				</div>
				<div class="form-error"
					 id="body_server_error"
					 *ngIf="form.get('body').errors?.serverError">
					{{ t(form.get('body').errors?.serverError) }}
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button"
					class="btn btn-default cancel"
					(click)="cancel()">{{ t('form.cancel') }}</button>
			<button type="submit"
					class="btn btn-success"
					[ladda]="loading"
					[disabled]="error">{{ t('form.save') }}</button>
		</div>
	</div>

</form>