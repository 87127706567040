<form [formGroup]="form"
	  novalidate
	  *transloco="let t;">

	<div class="modal-header modal-warning"
		 *transloco="let t;">
		<h4 class="modal-title"
			id="modal-basic-title">
			<i class="fas fa-exchange-alt mr-1"></i>
			<span>{{ t('user.switch.title') }}</span>
		</h4>
	</div>
	<div class="modal-body">

		<!-- user -->
		<div class="form-group">
			<label for="user">{{ t('user.switch.fields.user') }}</label>
			<ng-select name="user"
					   id="user"
					   bindLabel="full_name"
					   [items]="users"
					   [loading]="!(users)"
					   formControlName="user"
					   appendTo="body">
			</ng-select>
			<div class="form-error"
				 id="user_error"
				 *ngIf="form.get('user').errors?.required">
				{{ t('timesheet_entry.form.user.required_error') }}
			</div>
			<div class="form-error"
				 id="user_server_error"
				 *ngIf="form.get('user').errors?.serverError">
				{{ t(form.get('user').errors?.serverError) }}
			</div>
		</div>

	</div>
	<div class="modal-footer">
		<button type="button"
				class="btn btn-default cancel"
				(click)="cancel()">{{ t('form.cancel') }}</button>
		<button type="button"
				class="btn btn-success"
				(click)="submit()"
				[ladda]="loading"
				[disabled]="error">{{ t('form.save') }}</button>
	</div>

</form>