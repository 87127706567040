import { Component, OnInit } from '@angular/core';

/**
 * Describe the dashboard component
 * @author Vincent Dieltiens <v.dieltiens@jdc-airports.com>
 */
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
}
