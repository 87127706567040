// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, Output, ErrorHandler } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, registerLocaleData, Location } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeFrBE from '@angular/common/locales/fr-BE';
import localeFrBEExtra from '@angular/common/locales/extra/fr-BE';

// Core UI
import {
	PERFECT_SCROLLBAR_CONFIG,
	PerfectScrollbarConfigInterface,
	PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';
import { LoginComponent as DefaultLoginComponent } from './views/login/login.component';
/*import {
	//AppAsideModule,
	//AppHeaderModule,
	//AppFooterModule,
	//AppSidebarModule,
} from '@coreui/angular';*/
import {
	AvatarModule,
	BadgeModule,
	BreadcrumbModule,
	ButtonGroupModule,
	ButtonModule,
	CardModule,
	DropdownModule,
	FooterModule,
	FormModule,
	GridModule,
	HeaderModule,
	ListGroupModule,
	NavModule,
	ProgressModule,
	SharedModule as ASharedModule,
	SidebarModule,
	TabsModule as ATabsModule,
	UtilitiesModule,
} from '@coreui/angular';
import { IconModule, IconSetService } from '@coreui/icons-angular';
// Libs
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgChartsModule } from 'ng2-charts';
import { LaddaModule } from 'angular2-ladda';
import { translocoLoader } from './transloco.loader';
import { TranslocoModule, TRANSLOCO_CONFIG, TranslocoConfig, TranslocoService, TRANSLOCO_TRANSPILER } from '@ngneat/transloco';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';


// App
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing';
import { AppConfigService } from './services/app-config.service';
import { DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent  } from './containers';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './components/breadcrumb/breadcrumb.service';
import { LoginComponent } from './pages/login/login.component';
import { AppComponent } from './app.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordResetValidateComponent } from './pages/password-reset-validate/password-reset-validate.component';
import { AuthService } from './services/api/auth.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthInterceptorService } from './services/auth/auth.interceptor.service';
import { SharedModule } from './modules/shared/shared.module';
import { ToastrComponent } from './modules/shared/components/toastr/toastr.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { DeepTranspiler } from './transloco.transpiler';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SessionService } from './services/session.service';

registerLocaleData(localeFrBE, 'fr-BE', localeFrBEExtra);

// configure Bugsnag asap
Bugsnag.start({ apiKey: '03e8201b4315336705627e1f18f1f5c4' })

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
	return new BugsnagErrorHandler()
}


/**
 * Loads the configuration using the AppConfig service
 * @param appConfig
 */
export function appInitialized(appConfig: AppConfigService) {
	return (): Promise<any> => appConfig.load();
}

/**
 * Preload the translator before the app is initialised so that the
 * translations are available at app start.
 * @param transloco the transloco service
 * @reutrns a function to run to load the `fr` language
 */
export function preloadTranslations(transloco: TranslocoService) {
	return function () {
		return transloco.load('fr').toPromise();
	};
}


import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { BearerAutheticationInterceptorService } from './interceptors/bearer-authentication-interceptor.service';
import { StorageService } from './services/storage.service';
import { LogoutComponent } from './logout/logout.component';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { SentryErrorHandlerService } from './sentry-error-handler.service';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { SupportModule } from './modules/support/support.module';
import { TimezoneInterceptorService } from './interceptors/timezone-interceptor.service';
import { TitleComponent } from './components/title/title.component';
import { UserSwitchInterceptorService } from './interceptors/user-switch-interceptor.service';
import { UserSwitchModalComponent } from './components/user-switch-modal/user-switch-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';

//frLocale.longDateFormat.LLT = 'DD-MM-YYYY HH:mm';
//frLocale.longDateFormat.LLTS = 'DD-MM-YYYY HH:mm:ss';
defineLocale('fr', frLocale);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
};


export function getDatepickerConfig(): BsDatepickerConfig {
	return Object.assign(new BsDatepickerConfig(), {
		dateInputFormat: 'L LT'
	});
}
@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		// Core Ui
		AvatarModule,
		BreadcrumbModule,
		FooterModule,
		DropdownModule,
		GridModule,
		HeaderModule,
		SidebarModule,
		IconModule,
		NavModule,
		ButtonModule,
		FormModule,
		UtilitiesModule,
		ButtonGroupModule,
		SidebarModule,
		ASharedModule,
		ATabsModule,
		ListGroupModule,
		ProgressModule,
		BadgeModule,
		ListGroupModule,
		CardModule,
		//
		NgChartsModule,
		// Ladda
		LaddaModule.forRoot({
			style: 'slide-left'
		}),
		ToastrModule.forRoot({
			toastComponent: ToastrComponent,
			timeOut: 5000,
			progressBar: true
		}),
		TranslocoModule,
		// for HttpClient use:
		LoadingBarHttpClientModule,
		// for Router use:
		LoadingBarRouterModule,
		PasswordStrengthMeterModule,
		SharedModule,
		NgxMaskModule.forRoot(),
		ModalModule.forRoot(),
		DragToSelectModule.forRoot(),
		SupportModule,
		NgSelectModule,
		LoadingBarModule,
	],
	declarations: [
		AppComponent,
		DefaultLayoutComponent,
		DefaultHeaderComponent,
		DefaultFooterComponent,
		P404Component,
		P500Component,
		DefaultLoginComponent,
		LoginComponent,
		RegisterComponent,
		PasswordResetComponent,
		PasswordResetValidateComponent,
		DashboardComponent,
		BreadcrumbComponent,
		AdministrationComponent,
		LogoutComponent,
		ChangelogComponent,
		TitleComponent,
		UserSwitchModalComponent
	],
	providers: [
		{
			provide: ErrorHandler,
			useFactory: errorHandlerFactory
		},
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		{
			provide: LOCALE_ID,
			useValue: 'fr-BE'
		},
		AuthService,
		AppConfigService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitialized,
			multi: true,
			deps: [AppConfigService]
		},
		translocoLoader,
		{
			provide: TRANSLOCO_CONFIG,
			useValue: {
				availableLangs: ['fr'],
				prodMode: environment.production,
				defaultLang: 'fr',
				fallbackLang: 'fr'
			} as TranslocoConfig
		},
		{
			provide: APP_INITIALIZER,
			useFactory: preloadTranslations,
			multi: true,
			deps: [TranslocoService]
		},
		{
			provide: TRANSLOCO_TRANSPILER,
			useClass: DeepTranspiler
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BearerAutheticationInterceptorService,
			multi: true,
			deps: [StorageService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TimezoneInterceptorService,
			multi: true,
			deps: []
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
			deps: [Router, SessionService, Location, ToastrService, TranslocoService]
		},
		BsModalRef,
		{
			provide: BsDatepickerConfig,
			useFactory: getDatepickerConfig
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UserSwitchInterceptorService,
			multi: true,
			deps: [StorageService]
		},
		BreadcrumbService,
		{
			provide: ErrorHandler, useClass: SentryErrorHandlerService
		},
		IconSetService
	],
	exports: [
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
