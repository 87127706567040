import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/api/auth.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

	constructor(
		private route: Router,
		private auth: AuthService
	) { }

	ngOnInit() {
		this.auth.logout().subscribe();
		this.route.navigateByUrl('/login');
	}

}
