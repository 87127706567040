import { UntypedFormGroup, FormControl, AbstractControl, UntypedFormArray } from '@angular/forms';
import { Input, Directive, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
	selector: 'app-form',
	template: ''
})
export class FormComponent {
	@Input('errors') set _errors(errors: object) {
		//console.log('ERRRRR', errors)
		this.setServerErrors(errors);
		this.errors = errors;
	}

	public form: UntypedFormGroup;
	protected errors: any = 42;

	private subscriptions: Subscription[] = [];

	setServerErrors(errors: object) {
		if (!errors) {
			return;
		}

		this.setFieldErrors(errors, this.form);
	}

	protected setFieldErrors(errors: object, formGroup: AbstractControl) {
		//console.log('setFieldErrors', errors, formGroup)
		this.unsubscribleAllErrors();

		for (let [fieldName, fieldError] of Object.entries(errors)) {
			if (fieldError.errors && fieldError.errors.length > 0) {
				if (!formGroup.get(fieldName)) {
					throw new Error(`Can't set error on field ${fieldName}. Field does not exist`);
				}

				const errors = formGroup.get(fieldName).errors || {};
				errors.serverError = fieldError.errors[0];
				formGroup.get(fieldName).setErrors(errors);

				const subscription = formGroup.get(fieldName).valueChanges.subscribe(value => {
					this.removeServerError(formGroup.get(fieldName));
				});
				this.subscriptions.push(subscription);

				/*formGroup.get(fieldName).setErrors({
					serverError: fieldError.errors[0]
				});*/
			} else {
				// set serverError as null and if it's the only error on the field, remove it completely
				this.removeServerError(formGroup.get(fieldName));

				/*if (formGroup.get(fieldName) && formGroup.get(fieldName).errors && formGroup.get(fieldName).errors.serverError) {
					delete formGroup.get(fieldName).errors.serverError;
					if (JSON.stringify(formGroup.get(fieldName).errors) === JSON.stringify({})) {

						formGroup.get(fieldName).setErrors(null);
					}
				}*/
			}

			if (fieldError.children) {
				this.setFieldErrors(fieldError.children, formGroup.get(fieldName));
			}
		}
	}

	removeServerError(field: AbstractControl) {
		if (field && field.errors && field.errors.serverError) {
			delete field.errors.serverError;
			if (JSON.stringify(field.errors) == '{}') {
				field.setErrors(null);
			}
		}
	}

	unsubscribleAllErrors() {
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
		this.subscriptions = [];
	}

	ngOnDestroy() {
		this.unsubscribleAllErrors();
	}

	requiredFieldsMissing() {
		for (let controlName in this.form.controls) {
			let control = this.form.controls[controlName];
			if (control.errors) {
				if (control.errors.required) {
					return true;
				}
			}
		}

		return false;
	}

	getMissingRequiredFields(form: UntypedFormGroup | UntypedFormArray = null, path: string = '') {
		if (!form) {
			form = this.form;
		}

		let missing = [];
		for (let controlName in form.controls) {
			let control = form.controls[controlName];
			let fullPath = path ? path + '.' + controlName : controlName;
			if (control.errors) {

				if (control.errors.required) {
					missing[fullPath] = control;
				}

				/*console.log('error in ', path + '.' + controlName, control.errors)
				if (control.errors.required) {
					missing.push(path ? path + '.' + controlName : controlName)
				}*/
			}

			if (control instanceof UntypedFormArray || control instanceof UntypedFormGroup) {
				if (control.controls) {
					let subMissing = this.getMissingRequiredFields(control, fullPath);
					//missing.push(...subMissing);
					Object.assign(missing, subMissing);
				}
			}
		}

		return missing;
	}

	focusOnControl(control) {
		if (control instanceof NgSelectComponent) {
			control.focus();
		}
	}

}