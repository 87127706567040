import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { StorageService } from '@app/services/storage.service';

@Injectable({
	providedIn: 'root'
})
export class BearerAutheticationInterceptorService implements HttpInterceptor {

	constructor(private localStorage: StorageService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.handleAccess(request, next));
	}

	public async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		const token = await localStorage.getItem('token');
		let changedRequest = request;
		const headers = {};
		for(const key of request.headers.keys()) {
			headers[key] = request.headers.getAll(key);
		}
		if (token) {
			headers['Authorization'] = `Bearer ${token}`;
		}
		const newHeaders = new HttpHeaders(headers);

		changedRequest = request.clone({
			headers: newHeaders
		})
		return next.handle(changedRequest).toPromise();
	}
}
