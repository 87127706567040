import { Injectable, ErrorHandler } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import Bugsnag from '@bugsnag/js'
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SentryErrorHandlerService extends ErrorHandler {

	constructor() {
		super();
	}

	handleError(error) {
		//console.log('ERROR : ', error);
		/*StackTrace.fromError(error).then(stackFrames => {
			StackTrace.report(stackFrames, '/api/debug/stacktrace', 'Test');
		});*/
		if (environment.production) {
			Bugsnag.notify(error);
		}
		super.handleError(error);
	}
}