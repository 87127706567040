import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TimezoneInterceptorService implements HttpInterceptor {

	constructor() { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.handleAccess(request, next));
	}

	public async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {

		// request.headers is a readonly object, need to created a new one
		const headers = {};
		for(const key of request.headers.keys()) {
			headers[key] = request.headers.getAll(key);
		}
		headers['X-Timezone-Offset'] = this.getTimezoneOffset();

		let changedRequest = request.clone({
			headers: new HttpHeaders(headers)
		})
		return next.handle(changedRequest).toPromise();
	}

	private getTimezoneOffset(): string {
		return String(new Date().getTimezoneOffset());
	}
}
