import { HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '@app/services/storage.service';
import { from, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserSwitchInterceptorService {

	constructor(private localStorage: StorageService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.handleAccess(request, next));
	}

	public async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		const userSwitch = await localStorage.getItem('switch_user');

		if (!userSwitch) {
			return next.handle(request).toPromise();
		}

		let changedRequest = request;
		const headers = {};
		for(const key of request.headers.keys()) {
			headers[key] = request.headers.getAll(key);
		}
		if (userSwitch) {
			headers['x-switch-user'] = `${userSwitch}`;
		}
		const newHeaders = new HttpHeaders(headers);

		changedRequest = request.clone({
			headers: newHeaders
		});
		return next.handle(changedRequest).toPromise();
	}
}
