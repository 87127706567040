import { Component, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { AuthService } from '@app/services/api/auth.service';

@Component({
	selector: 'app-administration',
	templateUrl: './administration.component.html',
	styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

	user: User;

	constructor(private authService: AuthService) { }

	ngOnInit() {
		this.user = this.authService.getCurrentAuthenticatedUser();
	}

}
