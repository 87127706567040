import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from '../../services/api/auth.service';
import { StorageService } from '../../services/storage.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IssueAddModalComponent } from '@app/modules/support/components/issue-add-modal/issue-add-modal.component';
import { ApiService } from '@app/services/api/api.service';
import { User } from '@app/models/user';
import { Router } from '@angular/router';
import { UserSwitchModalComponent } from '@app/components/user-switch-modal/user-switch-modal.component';

/**
 * Represents the main layout of the app (only visible when logged)
 */
@Component({
	selector: 'app-dashboard',
	styleUrls: ['./default-layout.component.scss'],
	templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
	/** The items of the left nav */
	public navItems = [...navItems];
	/** Indicates if the sidebar is minimized */
	public sidebarMinimized = false;
	/** A change observer (for the minimization of the sidebar when the class `sidebar-minized` is added) */
	private changes: MutationObserver;
	/** The body tag */
	public element: HTMLElement;

	public user: any;

	private switchedUser: String = null;

	public perfectScrollbarConfig = {
		suppressScrollX: true,
	};

	/**
	 * Constructs the component
	 * @param _document
	 */
	constructor(
		transloco: TranslocoService,
		private authService: AuthService,
		private apiService: ApiService,
		private storageService: StorageService,
		private modalService: BsModalService,
		private router: Router,
		@Inject(DOCUMENT) _document?: any
	) {
		for (const index of Object.keys(navItems)) {
			if (!navItems[index].translated) {
				navItems[index].name = transloco.translate(navItems[index].name);
				navItems[index].translated = true;
			}
		}

		this.changes = new MutationObserver((mutations) => {
			this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
		});
		this.element = _document.body;
		this.changes.observe(<Element>this.element, {
			attributes: true,
			attributeFilter: ['class']
		});

		this.authService.authenticatedUser$.subscribe(user => {
			this.user = user;

			if (!user) {
				return;
			}

			this.navItems = [];
			for (const index of Object.keys(navItems)) {

				let canDisplay = true;
				if (navItems[index].permissions) {
					canDisplay = false;
					for (const permission of navItems[index].permissions) {
						if (user.hasPermission(permission)) {
							canDisplay = true;
							break;
						}
					}
				}

				if (navItems[index].show) {
					canDisplay = navItems[index].show(user);
				}

				if (canDisplay) {
					this.navItems.push(navItems[index]);
					//console.log('dont display ', this.navItems[index]);
					//delete(this.navItems)
				}
			}
		});


	}

	openSupportModal($event) {

		let initialState = {};

		let modal = this.modalService.show(IssueAddModalComponent, { initialState, class: 'issue-modal' });
	}

	minimizedChange($event) {
		this.storageService.set('layout.menu.minimized', $event);
	}

	canSwitchUser() {
		return this.user.hasPermission('permission.user.switch');
	}

	startSwitchUser($event) {
		let modal = this.modalService.show(UserSwitchModalComponent, {
			initialState: {},
			class: 'switch-user-modal'
		});

		modal.content.onUserSwitched.then((user: User) => {
			console.log('user switch : ', user)
			this.storageService.set('switch_user', user.email);
			this.switchedUser = user.email;
			window.location.reload();
		});
	}

	stopSwitchUser($event) {
		this.storageService.remove('switch_user');
		this.switchedUser = null;
		window.location.reload();
	}

	async ngOnInit() {
		this.sidebarMinimized = (await this.storageService.get('layout.menu.minimized', false)) == "true" ? true : false;
		this.switchedUser = (await this.storageService.get('switch_user', null));
	}

	/**
	 * Callback when the component is removed
	 */
	ngOnDestroy(): void {
		this.changes.disconnect();
	}
}
