import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserSwitchModalComponent } from '@app/components/user-switch-modal/user-switch-modal.component';
import { User } from '@app/models/user';
import { IssueAddModalComponent } from '@app/modules/support/components/issue-add-modal/issue-add-modal.component';
import { AuthService } from '@app/services/api/auth.service';
import { StorageService } from '@app/services/storage.service';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-default-header',
    templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {

    @Input() sidebarId: string = "sidebar";

    public newMessages = new Array(4)
    public newTasks = new Array(5)
    public newNotifications = new Array(5);

    public user: any;
    private switchedUser: String = null;

    constructor(
        private classToggler: ClassToggleService,
        private authService: AuthService,
        private modalService: BsModalService,
        private storageService: StorageService
    ) {
        super();

		this.init();

        this.authService.authenticatedUser$.subscribe(user => {
			this.user = user;

			if (!user) {
				return;
			}

			/*this.navItems = [];
			for (const index of Object.keys(navItems)) {

				let canDisplay = true;
				if (navItems[index].permissions) {
					canDisplay = false;
					for(const permission of navItems[index].permissions) {
						if (user.hasPermission(permission)) {
							canDisplay = true;
							break;
						}
					}
				}

				if (navItems[index].show) {
					canDisplay = navItems[index].show(user);
				}

				if (canDisplay) {
					this.navItems.push(navItems[index]);
					//console.log('dont display ', this.navItems[index]);
					//delete(this.navItems)
				}
			}*/
		});
    }

	async init() {
		this.switchedUser = await localStorage.getItem('switch_user');
		console.log('user switched : ', this.switchedUser);
	}

    openSupportModal($event) {

		let initialState = {};

		let modal = this.modalService.show(IssueAddModalComponent, { initialState, class: 'issue-modal' });
	}

    canSwitchUser() {
		return this.user.hasPermission('permission.user.switch');
	}

	startSwitchUser($event) {
		let modal = this.modalService.show(UserSwitchModalComponent, {
			initialState: {},
			class: 'switch-user-modal'
		});

		modal.content.onUserSwitched.then((user: User) => {
			console.log('user switch : ', user)
			this.storageService.set('switch_user', user.email);
			this.switchedUser = user.email;
			window.location.reload();
		});
	}

	stopSwitchUser($event) {
		this.storageService.remove('switch_user');
		this.switchedUser = null;
		window.location.reload();
	}

}