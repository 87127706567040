import { animation, style, query, animateChild, group, animate, state } from '@angular/animations';

import { fadeIn, fadeOut } from './fade';

/**
 * The basic Fade in (:enter) and fade out (:leave) animation definitions
 */
const fadeInFadeOut = [
	...fadeIn,
	...fadeOut,
	query(':enter', [
		animate('{{time}}', style({ opacity: 100 }))
	], { optional: true }),
	query(':leave', [
		animate('{{time}}', style({ opacity: 0 }))
	], { optional: true })
];

/**
 * The basic animation
 * @param time the duration of the animations
 */
export const fadeOutFadeInAnimation = animation([...fadeInFadeOut]);

/**
 * The fade in (:enter) and fade out (:leave) animations for the child
 * @param time the duration of the animations
 */
export const fadeOutFadeInChildAnimation = animation([
	...fadeInFadeOut,
	query(':enter', animateChild(), { optional: true }),
	query(':leave', animateChild(), { optional: true }),
]);

/**
 * The fade in (:enter) animation
 * @param time the duration of the animation
 */
export const fadeInAnimation = animation([
	...fadeIn,
	query(':enter', [
		animate('{{time}}', style({ opacity: 100 }))
	], { optional: true })
]);

/**
 * The fade out (:leave) animation
 * @param time the duration of the animation
 */
export const fadeOutAnimation = animation([
	...fadeOut,
	query(':leave', [
		animate('{{time}}', style({ opacity: 0 }))
	], { optional: true })
]);

/*
export const fadeOutFadeInChildAnimation = animation([
	style({ opacity: 100 }),
	query(':enter', [
		style({ opacity: 0 })
	], { optional: true }),
	query(':leave', [
		style({ opacity: 100 })
	], { optional: true }),
	query(':enter', animateChild(), { optional: true }),
	query(':leave', animateChild(), { optional: true }),
	group([
		query(':enter', [
			animate('{{time}}', style({ opacity: 100 }))
		], { optional: true }),
		query(':leave', [
			animate('{{time}}', style({ opacity: 0 }))
		], { optional: true })
	])
]);
*/
