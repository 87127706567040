import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, ObservableInput, throwError, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, tap, finalize } from 'rxjs/operators';
import { AuthService } from '../api/auth.service';
import { SessionService } from '../session.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Intercepts any HttpRequest and checks if response has a 401 response error.
 * It it the case, it redirect to the login page
 */
@Injectable({
	providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

	constructor(
		private router: Router,
		private session: SessionService,
		private location: Location,
		private toastr: ToastrService,
		private transloco: TranslocoService
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError((err: any): ObservableInput<any> => {
			if (err.status === 401) {
				this.session.set('login-redirect-url', this.location.path());
				this.router.navigate(['login']);
				return throwError(err);
			} else if (err.status === 403) { // Access denied
				this.toastr.error(
					this.transloco.translate('error.403.message', {
						url: request.url
					}),
					this.transloco.translate('error.403.title')
				)
				return throwError(err);
			} else {
				return throwError(err);
			}
		}));
	}
}
