import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BreadcrumbService } from './breadcrumb.service';

/**
 * Reperesents A component to display a breadcrumb automatically
 * updated according to the routes.
 *
 * @author Vincent Dieltiens <v.dieltiens@jdc-airports.com> based on source code of CoreUI breadcrumb component
 */
@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
	/** Indicates if the breadcrumb is fixed or not */
	@Input() fixed: boolean;

	/** the actual displayed breadcrumb */
	public breadcrumbs;

	/** the class use to set the breadcrumb as fixed */
	private readonly fixedClass = 'breadcrumb-fixed';

	/**
	 * Construct the component
	 * @param document the document element of the page
	 * @param renderer
	 * @param service The breadcrumb service to ask for the current breadcrumb data
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private renderer: Renderer2,
		public service: BreadcrumbService,
	) { }

	/**
	 * Initialises the component
	 */
	public ngOnInit(): void {
		this.setFixed(this.fixed);
		this.breadcrumbs = this.service.breadcrumbs;
	}

	/**
	 * Removes the `breadcrumb-fixed` class when the component is destroyed
	 */
	ngOnDestroy(): void {
		this.renderer.removeClass(this.document.body, this.fixedClass);
	}

	/**
	 * Set the breadcrumb as fixed or not
	 * @param fixed true if the breadcrumb is fixed, false otherwise
	 */
	setFixed(fixed: boolean = this.fixed): void {
		if (fixed) {
			this.renderer.addClass(this.document.body, this.fixedClass);
		}
	}
}
